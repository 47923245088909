import RestMethodBadge from "../../components/RestMethodBadge.tsx";

interface ApiDetailsHeaderProps {
  selectedPath: { value: string | null };
  selectedMethod: { value: string | null };
  description?: string;  // Optional field for the description
}

export default function ApiDetailsHeader({ selectedPath, selectedMethod, description }: ApiDetailsHeaderProps) {
  // Extract the path and method from the signals
  const path = selectedPath.value;
  const method = selectedMethod.value;

  if (!path || !method) {
    return (
      <div class="mb-4 mt-6 text-2xl font-bold">
        <p>Loading endpoint data...</p>
      </div>
    );
  }

  return (
    <div class="mb-4 mt-6">
      <div class="text-2xl font-bold flex items-center flex-wrap">
        <RestMethodBadge method={method} />
        <span class="ml-2 break-all">{path}</span>
      </div>
      <h4 class="text-sm mb:text-base mt-2 font-semibold text-gray-700 mb-6">
        {description || "No description available"}
      </h4>
    </div>
  );
}
